import React from "react"
import _ from "lodash"
import sectionStyles from "src/styles/components/section.module.scss"
import styles from "src/styles/components/static-footer.module.scss"
import Grid from "@material-ui/core/Grid"
import cx from "classnames"
import siteInfo from "content/data/info.json"

export default function Footer(props) {
  return (
    <Grid
      container
      spacing={2}
      component="header"
      className={cx(sectionStyles.root, sectionStyles.colored, styles.root)}
    >
      <Grid
        container
        item
        alignItems="flex-start"
        direction="column"
        className={styles.contact}
        xs={12}
        md={5}
      >
        <div>
          <a href="/">
            <img
              alt="logo"
              className={styles.logoAlt}
              src={siteInfo.logo_alt}
            />
          </a>
        </div>
        <p className={styles.description}>{siteInfo.description}</p>
        <div className={styles.social}>
          {_(siteInfo.social)
            .filter("url")
            .map(({ url, title, icon }) => (
              <a href={url} key={title}>
                <img alt={title} src={icon} title={title} />
              </a>
            ))
            .value()}
        </div>
        <p>
          © {siteInfo.company_name} {new Date().getFullYear()}
        </p>
      </Grid>
      <Grid
        className={styles.linksContainer}
        container
        item
        justify="flex-start"
        alignContent="flex-start"
        xs={12}
        sm={8}
        md={5}
      >
        {_.map(_.chunk(siteInfo.links, 3), (links, index) => (
          <div key={`col-${index}`} className={styles.linkColumn}>
            {_.map(links, ({ title, url, action }) => {
              if (action) {
                if (action === "openCookiesManagementModal") {
                  return (
                    <div key={title}>
                      <a href="#" onClick={props.openCookiesManagementModal}>
                        {title}
                      </a>
                    </div>
                  )
                }
              } else {
                return (
                  <div key={title}>
                    <a href={url}>{title}</a>
                  </div>
                )
              }
            })}
          </div>
        ))}
      </Grid>
      <Grid className={styles.linksContainer} item xs={12} sm={4} md={2}>
        <div>{siteInfo.contactUsText}</div>
        <a href={`mailto:${siteInfo.email}`}>{siteInfo.email}</a>
        <br />
        <a href={`tel:${siteInfo.phone}`}>{siteInfo.phone}</a>
      </Grid>
    </Grid>
  )
}
