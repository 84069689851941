import CookieConsent from "react-cookie-consent"
import PropTypes from "prop-types"
import React, { useState } from "react"
import siteInfo from "content/data/info.json"
import CookieManagementModal from "src/components/cookie-management-modal.jsx"
import Footer from "src/components/static-footer.jsx"
import Cookies from "js-cookie"

const gdprPluginAnalyticsCookieName = "gatsby-gdpr-google-analytics"

const localStorageCookiesBannerResponseKey = "cookiesBannerResponse"
function getCookiesBannerResponse() {
  if (typeof window !== "undefined") {
    const value = localStorage.getItem(localStorageCookiesBannerResponseKey)
    return value === null ? null : value === "true"
  } else {
    return null
  }
}

function persistCookiesBannerResponse(value) {
  window.localStorage.setItem(
    localStorageCookiesBannerResponseKey,
    value ? "true" : "false"
  )
}

function deleteGACookies() {
  const GA_COOKIES_DOMAIN = process.env.GA_COOKIES_DOMAIN
  if (Cookies.get("_ga")) {
    Cookies.remove("_ga", { path: "/", domain: GA_COOKIES_DOMAIN })
  }
  if (Cookies.get("_gid")) {
    Cookies.remove("_gid", { path: "/", domain: GA_COOKIES_DOMAIN })
  }
}

const Layout = ({ children, className }) => {
  let cookieBoolValue = null
  const cookieValue = Cookies.get(gdprPluginAnalyticsCookieName)
  if (cookieValue === "true") {
    persistCookiesBannerResponse(true)
    cookieBoolValue = true
  } else if (cookieValue === "false") {
    persistCookiesBannerResponse(false)
    Cookies.remove(gdprPluginAnalyticsCookieName)
    cookieBoolValue = false
  }
  if (cookieBoolValue == null || cookieBoolValue === false) {
    deleteGACookies()
  }
  const [hideCookieConsentBar, setHideCookieConsentBar] = useState(
    getCookiesBannerResponse() !== null
  )
  const [
    cookiesManagementModalIsOpen,
    setCookiesManagementModalIsOpen,
  ] = React.useState(false)
  const openCookiesManagementModal = () => {
    setCookiesManagementModalIsOpen(true)
  }

  const closeCookiesManagementModal = () => {
    setCookiesManagementModalIsOpen(false)
  }

  const onAccept = () => {
    persistCookiesBannerResponse(true)
  }

  const onDecline = () => {
    persistCookiesBannerResponse(false)
    deleteGACookies()
  }

  const saveAnalyticsCookie = value => {
    if (value == null) {
      Cookies.remove(gdprPluginAnalyticsCookieName)
    } else {
      Cookies.set(gdprPluginAnalyticsCookieName, "" + value)
    }
    persistCookiesBannerResponse(value)
    setHideCookieConsentBar(true)
    setCookiesManagementModalIsOpen(false)
  }

  return (
    <div className={className}>
      {children}
      {cookiesManagementModalIsOpen ? (
        <CookieManagementModal
          isOpen={cookiesManagementModalIsOpen}
          close={closeCookiesManagementModal}
          onSave={saveAnalyticsCookie}
          value={getCookiesBannerResponse()}
        />
      ) : null}
      <Footer openCookiesManagementModal={openCookiesManagementModal} />
      {hideCookieConsentBar ? null : (
        <CookieConsent
          setDeclineCookie={false}
          buttonClasses="button"
          buttonStyle={{
            background: null,
            color: null,
            border: null,
            borderRadius: null,
            boxShadow: null,
            cursor: null,
          }}
          buttonText={siteInfo.cookiePolicyAcceptText}
          enableDeclineButton
          declineButtonStyle={{
            background: null,
            color: null,
            border: null,
            borderRadius: null,
            boxShadow: null,
            cursor: null,
          }}
          declineButtonText="Ablehnen"
          cookieName="gatsby-gdpr-google-analytics"
          onAccept={onAccept}
          onDecline={onDecline}
        >
          {siteInfo.cookiePolicyText}
        </CookieConsent>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
