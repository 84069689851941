import React, { useState } from "react"
import translations from "content/data/modal/cookie-management.json"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

export default function CookieManagementModal(props) {
  const { de: page } = translations
  const [accept, setAccept] = useState(props.value)
  const [changed, setChanged] = useState(false)

  const onChange = event => {
    const target = event.target
    const acceptCookie = target.value === "true"
    setAccept(acceptCookie)
    setChanged(true)
  }
  const closeWithoutSaving = () => {
    props.close()
  }

  const closeAndSave = () => {
    props.onSave(accept)
  }

  return (
    <Dialog
      open={props.isOpen}
      scroll="paper"
      className="cookie-modal"
      onClose={closeWithoutSaving}
    >
      <DialogTitle>{page.intro_title}</DialogTitle>
      <DialogContent dividers={true}>
        <p className="first-paragraph">
          <span dangerouslySetInnerHTML={{ __html: page.intro_text }}></span>
        </p>
        <h5>{page.mandatory_cookies_title}</h5>
        <p>{page.mandatory_cookies_text}</p>
        <p className="goal">{page.mandatory_cookies_goal}</p>
        <h5>{page.analytics_cookies_title}</h5>
        <p>{page.analytics_cookies_text}</p>
        <p className="goal">{page.analytics_cookies_goal}</p>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            onChange={onChange}
          >
            <FormControlLabel
              value={true}
              checked={accept === true}
              control={<Radio color="primary" />}
              label="Annehmen"
            />
            <FormControlLabel
              value={false}
              checked={accept === false}
              control={<Radio color="primary" />}
              label="Ablehnen"
            />
          </RadioGroup>
        </FormControl>
        <DialogActions>
          <button onClick={closeAndSave} disabled={!changed}>
            Änderungen speichern
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
